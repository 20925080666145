import { grey } from '@mui/material/colors'
import { createTheme } from '@mui/material/styles'

import { config } from '@/config'
const { tenantConfig } = config

const colors = {
  primary: '#7f3f82',
  secondary: '#545759',
  banner: '#7f3f82',
  text: {
    primary: '#545759',
  },
}

const dimensions = {
  parameters: 380,
  results: 450,
}

const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    ph: 740,
    md: 900,
    lg: 1200,
    tb: 1350,
    xl: 1536,
  },
}

const theme = createTheme({
  breakpoints,
})

export const themes = {
  base: {
    palette: {
      primary: {
        main: colors.primary,
        contrastText: '#fff',
      },
      secondary: {
        main: colors.secondary,
        contrastText: '#fff',
      },
      default: {
        main: grey[300],
        light: grey[200],
        dark: grey[400],
      },
      background: {
        default: '#fff',
      },
      text: {
        primary: '#545759',
      },
    },

    typography: {
      fontSize: 13,
      fontFamily: ['Mulish', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(
        ', '
      ),
    },

    breakpoints,

    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            backgroundColor: '#ebeef1',
          },
        },
      },

      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              border: `1px solid ${colors.primary}`,
            },
          },
          input: {
            padding: '10px 12px',
          },
        },
      },

      MuiTabs: {
        styleOverrides: {
          root: {},
          indicator: {
            backgroundColor: '#fff',
          },
        },
      },

      PrivateTabIndicator: {
        styleOverrides: {
          root: {
            transition: 'all 30ms cubic-bezier(0.4, 0, 0.2, 1)',
          },
        },
      },

      MuiTab: {
        styleOverrides: {
          root: {
            textTransform: 'none',
            fontSize: '1rem',
          },
          textColorPrimary: {
            color: '#fff',
            opacity: 0.6,
            '&.Mui-selected': {
              color: '#fff',
              opacity: 1,
            },
          },
        },
      },

      // AMS

      AMSApp: {
        styleOverrides: {
          root: {
            width: null,
            maxWidth: 1650,
            margin: '0 auto',
            boxShadow: '0 0 6px 2px rgb(1 1 1 / 15%)',
            borderLeft: '1px solid rgba(1, 1, 1, 0.2)',
          },
        },
      },

      AMSMainMenu: {
        styleOverrides: {
          list: {
            paddingTop: 0,
          },
        },
      },

      AMSAppBar: {
        props: {
          elevation: 0,
          position: 'static',
        },

        styleOverrides: {
          root: {
            zIndex: theme.zIndex.drawer - 1,
            backgroundColor: 'white',
            color: '#545759',
            boxShadow: '0 0 4px 4px rgb(1 1 1 / 5%)',
            flexShrink: '1',
          },
        },
      },

      AMSAppBarGroup: {
        styleOverrides: {
          root: {
            [theme.breakpoints.down('sm')]: {
              padding: theme.spacing(0.5),
            },
          },
        },
      },

      AMSMenuButton: {
        styleOverrides: {
          root: {
            [theme.breakpoints.down('sm')]: {
              fontSize: '0.8rem',
            },
          },
        },
      },

      AMSAppBarTitle: {
        styleOverrides: {
          root: {
            [theme.breakpoints.down('sm')]: {
              flexGrow: 1,
            },
          },
          logo: {
            height: 78,
            width: 338,
            maxWidth: 338,
            // maxHeight: 78,
            [theme.breakpoints.down('sm')]: {
              maxWidth: '100%',
            },
          },
        },
      },

      // desktop search

      AMSDesktopSearch: {
        styleOverrides: {
          parameterDrawer: {
            minWidth: dimensions.parameters,
            maxWidth: dimensions.parameters,
            paddingTop: 100,
          },
          parameters: {
            width: dimensions.parameters,
          },
          results: {
            width: dimensions.results,
            [theme.breakpoints.between('ph', 'md')]: {
              width: 'calc(100vw / 2)',
            },
          },
        },
      },

      AMSDesktopSearchToolbar: {
        styleOverrides: {
          root: {
            backgroundColor: colors.banner,
            // backgroundColor: '#f0f',
            position: 'relative',
            '&::before': {
              display: 'block',
              // display: 'none',
              content: '""',
              position: 'absolute',
              background: `url(${tenantConfig.assets.toolbarBackground})`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              // backgroundPosition: '0 -50px',
              right: 0,
              top: 0,
              height: '100%',
              width: 350,
            },
          },
          toolbarButton: {
            color: '#fff',
          },
        },
      },
    },
  },
}

export default theme
