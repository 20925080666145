import React from 'react'

// import { styled } from '@mui/system'
import { makeStyles } from '@mui/styles'

import { Link as RouterLink } from 'react-router-dom'
import { Typography, Box, Button as MuiButton } from '@mui/material'

import { useConfig } from '@/config'
import SurveyLocation from '../../../../src/ui/survey/SurveyLocation'
import LocationInput from '../../../../src/ui/location/LocationInput'

import FAIcon from '@/ui/components/icon/FontAwesomeIcon'
import Button from '@/ui/components/buttons/Button'
import { useRootStore } from '@/stores/root/store'
import { useAnalytics } from 'use-analytics'

const ClickAway = (props) => {
  const { children, imgSrc, ...other } = props || {}

  return (
    <Box
      as="span"
      {...other}
      sx={{
        position: 'relative',
        backgroundColor: 'rgb(212,203,242, 0.2)',
        padding: '0 3px',
        color: '#6252a7',
        fontWeight: 'normal',
      }}
    >
      {children}
      <Box
        as="img"
        src={imgSrc}
        sx={{
          position: 'absolute',
          height: 30,
          bottom: -20,
          right: -16,
        }}
      />
    </Box>
  )
}

const LandingHero = () => {
  const assets = useConfig()?.tenantConfig?.assets?.landing
  const store = useRootStore()
  const params = store.search.params
  // const hero = heroImg
  const { track } = useAnalytics()

  const handleChange = (value) => {
    track('searchParamaterChange', {
      category: 'Search',
      label: 'location',
    })

    store.search.params.set('location', value)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column-reverse', md: 'row' },
        alignItems: 'stretch',
        gap: 4,
        // backgroundColor: '#eef',
        borderBottom: { md: '1px solid rgba(0, 0, 0, 0.1)' },
      }}
    >
      <Box
        sx={{
          // flex: '1 1 auto',
          flexGrow: 1,
          flexShrink: 0,
          flexBasis: { xs: 'auto', ph: 400 },
          display: 'flex',
          flexDirection: 'column',
          gap: { xs: 3 },
          justifyContent: 'space-evenly',
          // justifyContent: 'center',
          minWidth: { xs: 'auto', md: 400 },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: { xs: 'center', md: 'flex-start' },

            gap: 10,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: 2,
            }}
          >
            <Typography variant="h4" fontWeight="bold">
              Welcome to
              <br />
              <Box as="span" sx={{ color: 'primary.main' }}>
                Access My Healthcare
              </Box>
            </Typography>

            <Typography variant="body1" sx={{ maxWidth: 600 }}>
              {`Find health services in your local area. If you're looking for
              fixed or a visiting outreach service, you'll find it on Access My
              Healthcare.`}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: 3,
            }}
          >
            <Typography variant="h5" subvariant="landing" fontWeight="light">
              Local health services are a<br />
              <ClickAway imgSrc={assets.cursor}>click away</ClickAway>
            </Typography>
            <Box
              sx={{
                display: 'flex',
              }}
            >
              <Box
                sx={{
                  width: { xs: 220, md: 300 },
                  my: 0,
                  mx: 0.5,
                }}
              >
                <LocationInput
                  id="location-select-home"
                  value={params['location']}
                  onChange={(_, value) => handleChange(value)}
                  defaultLocationButton
                />
              </Box>

              <Button
                variant="contained"
                color="primary"
                component={RouterLink}
                startIcon={<FAIcon fa="fas fa-search" />}
                to="/search"
                size="Large"
                rounded
                sx={{
                  borderRadius: '0 100px 100px 0',
                  maxHeight: '49px',
                }}
              >
                Search
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          flex: '1 1 auto',
        }}
      >
        <Box
          src={assets.heroNoIcon}
          as="img"
          sx={{
            objectFit: 'contain',
            objectPosition: 'bottom',
            maxHeight: { xs: 300, sm: 400, md: 1 },
            width: 500,
            height: 700,
            borderBottom: { xs: '1px solid rgba(0, 0, 0, 0.1)', md: undefined },
          }}
        />
      </Box>
    </Box>
  )
}

export default LandingHero
