import shared from '../shared/assets'

import logo from './assets/images/mental-health-commission-logo.svg'
import mapMarker from './assets/images/marker-wapha.png'
import toolbarBackground from './assets/images/wapha-toolbar-background@2x.png'
import badge from './assets/images/wapha-logo-white-label.svg'

const { cie, headerIcons, healthengine } = shared

import '@fontsource/mulish/300.css'
import '@fontsource/mulish/400.css'
import '@fontsource/mulish/500.css'
import '@fontsource/mulish/700.css'
import '@fontsource/mulish/800.css'
import '@fontsource/mulish/900.css'

export const assets = {
  logo,
  cie,
  headerIcons,
  healthengine,
  mapMarker,
  toolbarBackground,
  badges: {
    default: badge,
  },
}
