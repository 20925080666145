import { addDays } from 'date-fns'
import { toJS } from 'mobx'
import { config } from '@/config'
// default mappings

const paramMaps = {
  age: {
    values: {
      '0 - 15': { ageMin: 0, ageMax: 15 },
      '16 - 25': { ageMin: 16, ageMax: 25 },
      '26 - 40': { ageMin: 26, ageMax: 40 },
      '41 - 64': { ageMin: 41, ageMax: 64 },
      '65+': { ageMin: 65, ageMax: 999 },
    },
    default: { AgeMin: 0, AgeMax: 999 },
  },

  gender: {
    values: {
      Male: 'MaleOnly',
      Female: 'FemaleOnly',
    },
    default: null,
  },
}

const mapParam = (param, value) => {
  const m = paramMaps[param]
  if (!m) {
    console.warn(`No param mapping found for "${param}"`)
    return
  }

  return m.values[value] || m.default
}

// builders

const builders = {
  directory: {
    fixed: ({ fields }) => fields,

    page: ({ pageNumber = 1, pageSize = 20 }) => {
      return { pageNumber, pageSize }
    },

    sort: ({ sortField, sortDirection }) => {
      // return { sortField: param.sortField}
      return { sortField, sortDirection }
    },

    query: ({ query }) => {
      return { searchTerm: query || '' }
    },

    categories: ({ categories }) => {
      return { categories: categories, categoriesRequired: true }
    },

    commissionedServices: ({ commissionedServices }) => {
      const cs = commissionedServices.length > 0 ? commissionedServices : [-1]
      return { commissionedServices: cs }
    },

    location: ({ location }) => {
      return { latitude: location.lat, longitude: location.lng }
    },

    // single region (wapha geog)
    locationRegion: ({ location }) => {
      if (location.type !== 'geography')
        console.warn('Location is not geography type', location)
      return { locationRegions: [location.id] }
    },

    // locationRegions override (ihelp)
    locationRegions: ({ locationRegions }) => {
      return { locationRegions }
    },

    serviceRegions: ({ queryBody, location }) => {
      const { appConfig: ac } = config

      if (location.type !== 'geography')
        console.warn('Location is not geography type', location)

      const gids =
        location.lgas?.length > 0 && ac?.tenant === 'wapha'
          ? location.lgas
          : [location.id]

      return {
        extendedFilters: [
          ...(queryBody.extendedFilters || []),
          {
            nested: {
              path: 'serviceRegions',
              query: {
                terms: {
                  'serviceRegions.geographyId': gids,
                },
              },
            },
          },
        ],
      }
    },

    radius: ({ radius }) => {
      return { radius }
    },

    age: ({ age }) => {
      return { age: mapParam('age', age) }
    },

    gender: ({ gender }) => {
      return { gender: mapParam('gender', gender) }
    },

    filters: ({ queryBody, filters }) => {
      if (!filters) {
        console.warn(`Invalid filters`)
        return
      }

      return {
        extendedFilters: [
          ...(queryBody.extendedFilters || []),
          {
            bool: {
              should: [{ terms: { categories: [-1] } }, ...filters],
              minimum_should_match: 1,
            },
          },
        ],
      }
    },

    rawFilters: ({ rawFilters }) => {
      return { extendedFilters: rawFilters }
    },
  },

  // diary: {
  //   fixed: ({ fields }) => fields,

  //   page: ({ pageNo = 1, pageSize = 20 }) => {
  //     return { PageNo: pageNo, PageSize: pageSize }
  //   },

  //   query: ({ query }) => {
  //     return { WhatText: query || '' }
  //   },

  //   categories: ({ categories }) => {
  //     return { ServiceCategories: categories }
  //   },

  //   locationId: ({ location }) => {
  //     if (location.type !== 'locality') console.warn('Location is not locality type', location)
  //     return { LocationId: [location.id] }
  //   },

  //   radius: ({ radius }) => {
  //     return { RadiusKM: radius }
  //   },

  //   dateRange: ({ dateRange }) => {
  //     if (dateRange.delta < 0) {
  //       const from = dateRange.start || new Date()
  //       const to = dateRange.end ? addDays(dateRange.end, 1) : addDays(from, 90)

  //       return {
  //         WhenIndex: 'Custom',
  //         SearchFrom: from.toISOString(),
  //         SearchTo: to.toISOString(),
  //       }
  //     }

  //     console.error(`date delta not implemented ${dateRange.delta}`)
  //   },

  //   diaryFormat: ({ diaryFormat }) => {
  //     // const df = diaryFormat.length > 0 ? diaryFormat : [-1]
  //     return { DiaryFormatId: diaryFormat }
  //   },
  // },

  diary: {
    fixed: ({ fields }) => fields,

    page: ({ pageNo = 1, pageSize = 20 }) => {
      return { pageNo, pageSize: pageSize }
    },

    query: ({ query }) => {
      return { whatText: query || '' }
    },

    categories: ({ categories }) => {
      return { serviceCategories: categories }
    },

    locationId: ({ location }) => {
      if (location.type !== 'locality')
        console.warn('Location is not locality type', location)
      return { locationId: location.id }
    },

    radius: ({ radius }) => {
      return { radiusKM: radius }
    },

    dateRange: ({ dateRange }) => {
      if (dateRange.delta < 0) {
        const from = dateRange.start || new Date()
        const to = dateRange.end ? addDays(dateRange.end, 1) : addDays(from, 90)

        return {
          whenIndex: 'Custom',
          searchFrom: from.toISOString(),
          searchTo: to.toISOString(),
        }
      }

      console.error(`date delta not implemented ${dateRange.delta}`)
    },

    diaryFormat: ({ diaryFormat }) => {
      // const df = diaryFormat.length > 0 ? diaryFormat : [-1]
      return { diaryFormatId: diaryFormat }
    },
  },
}

export const buildQuery = ({ query, params, paramOverrides }) => {
  const { builder: builderId } = query
  const builder = builders[builderId]

  if (!builder) {
    console.error(`Builder not found for ${builderId}`)
    return
  }

  const builtQuery = query.parameters.reduce(
    (queryBody, { param, ...pargs }) => {
      const paramBuilder = param && builder[param]

      if (!paramBuilder) {
        console.warn(`No query builder for param "${param}"`)
        return queryBody
      }

      const paramResult = paramBuilder({ queryBody, ...params, ...pargs })
      return { ...queryBody, ...paramResult }
    },
    {}
  )

  // console.log({ queryBody: qb })
  return { ...builtQuery, ...paramOverrides }
}
