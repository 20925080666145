import React, { useEffect, useState } from 'react'
// import clsx from 'clsx'

import { observer } from 'mobx-react-lite'

import { BrowserRouter as Router } from 'react-router-dom'

import { useAnalytics } from 'use-analytics'

import { styled } from '@mui/material/styles'
import { withStyles } from '@mui/styles'

import { SwipeableDrawer } from '@mui/material'

import AppBar from '@/ui/nav/app-bar/AppBar'
import MainMenu from '@/ui/nav/menus/MainMenu'
import TenantRoutes from '@/ui/router/TenantRoutes'
import { FacebookPixelTag } from './FacebookPixelTag'
import { GoogleSiteVerification } from './GoogleSiteVerification'

import { useConfig } from '@/config'
import { useResizeDetect } from '@/ui/hooks/useResizeDetect'
import { useAppAnalytics } from '@/ui/hooks/useAppAnalytics'
import { usePlatform } from '@/ui/hooks/usePlatform'

import { useDebugStore } from '@/stores'
import { DebugHelper, ReactQueryDevtools } from '@/ui/debug/debug-helper'

import { useAtomsDevtools } from 'jotai/devtools'
import { MstReduxDevToolsProvider } from '@/stores/debug/mstReduxDevTools'

import { ReduxDevtoolsMonitors } from '@/ui/debug/redux-devtools/ReduxMonitors'
import useGoogleAnalyticsTagManager, {
  removeDuplicateScript,
} from '@/ui/hooks/useGoogleAnalyticsTagManager'
import _ from 'lodash'

// const bottomOffset = 0

// prevent ios full window scroll from touch
const preventDefault = (e) => e.preventDefault()

// window.addEventListener('touchmove', preventDefault, {
//   passive: false,
// })

// window.removeEventListener('touchmove', preventDefault)

const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    // backgroundColor: '#f00',
    maxHeight: (props) => props.height,
    height: (props) => props.height,
    // maxHeight: '100vh',
    // height: '100vh',
    maxWidth: '100vw',
    width: '100vw',

    display: 'flex',
    flexDirection: 'column',
  },
  appBar: {
    flex: '0 1 0',
  },
  content: {
    flex: `1 0 0`,
    overflow: 'hidden',
  },
})

const DebugHelper_ = () => {
  const { appConfig } = useConfig()
  if (!appConfig.debugHelpers) return null
  return <DebugHelper />
}

const ReactQueryDevtools_ = observer(() => {
  const { appConfig } = useConfig()
  const debug = useDebugStore()

  if (!appConfig.debugHelpers || !debug.reactQueryDevtools) return null

  return (
    <ReactQueryDevtools
      initialIsOpen={false}
      toggleButtonProps={{ className: 'ReactQueryDevtoolsToggleButton' }}
    />
  )
})

const AppMain = withStyles(styles, { name: 'AMSApp' })((props) => {
  const { classes } = props
  const { tenantConfig: tc } = useConfig()
  const { track } = useAnalytics()

  const [mainMenu, setMainMenu] = useState(false)

  const handleClickMenu = () => {
    track('mainMenuToggle', {
      category: 'Navigation',
      label: 'Main Menu',
    })
    toggleMenu(!mainMenu)()
  }

  const toggleMenu = (open) => () => {
    setMainMenu(open)
  }

  // hooks have to go under router

  const AppAnalytics = () => {
    useAppAnalytics()
    if (!_.isUndefined(tc.analytics?.googleAnalytics?.containerId)) {
      useGoogleAnalyticsTagManager(tc.analytics?.googleAnalytics?.containerId)
      removeDuplicateScript()
    }
    return null
  }

  return (
    // <MstReduxDevToolsProvider>
    <Router>
      <DebugHelper_ />
      <AppAnalytics />
      <FacebookPixelTag />
      <GoogleSiteVerification />
      <div className={classes.root}>
        <AppBar onMenuClick={handleClickMenu} {...tc.ui?.appBar} />
        <div className={classes.content}>
          <TenantRoutes />
          {/* <ReduxDevtoolsMonitors /> */}
          <ReactQueryDevtools_ />
        </div>
      </div>
      <SwipeableDrawer
        anchor="left"
        open={mainMenu}
        onOpen={toggleMenu(true)}
        onClose={toggleMenu(false)}
      >
        <MainMenu menu="navigation" onToggleMenu={toggleMenu} />
      </SwipeableDrawer>
    </Router>
    // </MstReduxDevToolsProvider>
  )
})

export const AppContainer = () => {
  const { height } = useResizeDetect()
  const platform = usePlatform()

  const appHeight = platform.ieVersion ? height - 1 : height

  return <AppMain height={appHeight} />
}
