import React from 'react'

import PerfectScrollbar from 'react-perfect-scrollbar'

import { makeStyles } from '@mui/styles'
import {
  Container,
  // Typography,
} from '@mui/material'

import BackButton from '@/ui/nav/BackButton'
import Footer from '@/ui/footer/Footer'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    // minHeight: '100%',
    // maxHeight: '100%',
    height: '100%',
    overflow: 'hidden',
  },
  main: {
    flex: '1 0 auto',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(12),
  },
}))

const ContentPage = ({ children, backButton = true }) => {
  const classes = useStyles()

  return (
    <PerfectScrollbar
      className={classes.root}
      id="scroll-content"
      options={{
        minScrollbarLength: 50,
      }}
    >
      <Container
        // TODO: add to config
        // maxWidth="md"
        maxWidth="tb"
        component="main"
        className={classes.main}
      >
        <div id="content-page-top" />
        {backButton && <BackButton />}
        {children}
      </Container>
      <Footer />
    </PerfectScrollbar>
  )
}

export default ContentPage
