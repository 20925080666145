import { EventResult } from '.'
import { ServiceResult } from './ServiceResult.model'
import { formatDistance, convertServiceChanges } from './common'
import { EventDataViewModel } from '@ciss/cie-api-orval'
import { SetRequired } from 'type-fest'

/**
 * Transformer to convert CieApi Event to {@link EventResult}
 *
 * @category Models
 */
export const eventResultTransformer = (data: EventDataViewModel) => {
  try {
    const e = data

    const latLng = {
      lat: e?.geocodeLat || 0.0,
      lng: e?.geocodeLong || 0.0,
    }

    const distance = e.distance || 0

    const address = [e.locationTitle, e.locationAddress].join(' ').trim()

    const result: EventResult = {
      type: 'event',
      id: `event_${e.eventInstanceID}`,
      search: '',
      name: e.eventName || '',
      day: 'blah', // moment(e.startDate).format('dddd'),
      startDay: e.instanceStartDay || '',
      startMonth: e.instanceStartMonth || '',
      time: e.isAllDay ? 'All Day' : `${e.startTime} - ${e.endTime}`,
      address,
      addressWithheld: address === '--withheld--',
      phone: e.phone1 || '',
      description: e.textOnlyDescription || '',
      url: e.instanceUrl || '',
      // appointmentRequired: e.isAppointmentRequired,
      // appointmentAvalable: e.appointmentAvailability,
      // referralRequired: e.isReferralRequired,
      // additionalInfo: e.additionalInfo,
      // notes: e.notes,
      // serviceChanges: undefined,
      startDate: e.startDate,
      endDate: e.endDay,
      startAt: e.startsAt,
      endAt: e.endsAt,
      repeats: e.repeats,
      repeatEvery: e.repeatEvery,
      repeatOnDay: e.repeatOnDay,
      isAllDay: e.isAllDay,
      distance,
      distanceLabel: formatDistance(distance),
      ...latLng,
      geometry: latLng,
    }

    return result
  } catch (e) {
    console.warn(`Failed to transform service id: ${data?.eventInstanceID}`)
  }
}
