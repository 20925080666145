/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable no-undef */
import React, { useState } from 'react'

import { atom, useAtom } from 'jotai'
import { useAtomValue } from 'jotai/utils'

const loadTenantConfig: any = () => {
  return require(`../tenants/${process.env.TENANT}/config.js`).default
  // return require(`../tenants/${process.env.TENANT}/config.ts`).default
}

// interface ConfigState {
//   config: any // replace with AmsConfig
//   context: React.Context<any>
// }

const configState = (() => {
  const appConfig = {
    version: 3,
    apiKey: process.env.REACT_APP_CIE_API_KEY,
    apiURL: process.env.REACT_APP_CIE_API_URL,
    directoryURL: process.env.REACT_APP_DIRECTORY_URL,
    diaryURL: process.env.REACT_APP_DIARY_URL,
    cdnURL: process.env.REACT_APP_CDN_URL,
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    fullCalendarProductKey: process.env.REACT_APP_CALENDAR_KEY,
    debugHelpers: process.env.REACT_APP_DEBUG_HELPERS === 'true',
    tenant: process.env.TENANT,
  }

  const tenantConfig = loadTenantConfig()

  const legacyConfig = {
    // legacy
    appConfig,
    tenantConfig,
  }

  const config = {
    app: appConfig,
    ...tenantConfig,
  }

  return {
    legacyConfig,
    config,
  }
})()

// @ts-ignore
window.config = configState.config

// const context = React.createContext(configState.config)
// const legacyContext = React.createContext(configState.legacyConfig)

const configAtom = atom(configState.legacyConfig)

/**
 * Exports static config for availability to non-react modules
 *
 * This is an antipattern which needs to be replaced with an async function as in
 * future it will need to use ES `async import()` instead of webpack's synchronous
 * `require()`.
 */
export const config = configState.legacyConfig

/**
 * Hook for loading config in react
 *
 * currently most imports use
 *
 * ```ts
 * const { tenantConfig: tc } = useConfig()
 * ```
 */
export const useConfig = (): any => useAtomValue(configAtom)
