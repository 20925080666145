import React from 'react'
import _ from 'lodash'
import { observer } from 'mobx-react-lite'
import deepmerge from 'deepmerge'
import { Box } from '@mui/material'

import Badge from './Badge'
import { config } from '@/config'
import { useRootStore } from '@/stores/root'

const { tenantConfig: tc } = config

const badges = (tc.categories || [])
  .filter((c) => !!c.badge)
  .map((c) => ({
    // ...c.badge,
    tagId: c.badge.tagId,
    category: c.id,
    label: c.title,
    styles: {
      default: {
        backgroundColor: c.badge.backgroundColor,
      },
    },
  }))

const BadgeList = observer((props) => {
  const { config, data } = props || {}
  const { options } = config

  const store = useRootStore()

  const resultBadges = badges
    .filter((badge) => data.serviceTags?.includes(badge.tagId))
    .map((badge) =>
      deepmerge(options, {
        ...badge,
        active: store.search.activeCategories?.includes(badge.category),
      })
    )

  if (resultBadges.length === 0) return null

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        mt: 1,
        mb: 3,
      }}
    >
      {resultBadges.map((badge, i) => (
        <Badge key={i} {...badge} />
      ))}
    </Box>
  )
})
export default BadgeList
